import {Link} from 'react-router-dom';

export default function Footer() {
    return (<>
        <footer>
            <div id="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-info float-left">
                                <p>MULTI-PLAN_SQlRT9KnGb2024_M - All copyrights reserved © 2023 - Designed &
                                    Developed by <a href="https://mediquotes.us/" rel="nofollow">Mediquotes</a></p>
                            </div>
                            <div className="float-right">
                                <ul className="nav nav-inline">
                                    <li className="nav-item">
                                        <Link to="/privacy" className="nav-link active">Privacy Policy</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/partners" className="nav-link active">Business Partners</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/terms-of-use" className="nav-link active">Terms of Services</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}
