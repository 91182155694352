import React from 'react';
import Footer from "./Footer";
import {Link} from "react-router-dom";

export default function TermsConditions() {
    var BreadCrumbTitle = "Affordable Connectivity Program Terms & Conditions";
    document.title = BreadCrumbTitle;
    return (
        <>
            <header id="home" className="hero-area-2">
                <div className="overlay"></div>
                <div className="navigation">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg">
                            <div className="navbar-brand"><Link to="/"><i
                                className="lni lni-pulse css-1n3ub8g"></i>Mediquotes</Link></div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <Link to="/#home">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/#features">Benefits</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="contents text-center">
                                <h2>INSURANCE QUOTE</h2>
                                <p>Our most comprehensive standardized Medigap insurance <br className="d-none"/>
                                    plans carries additional benefits</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div id="blog-single">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <div className="blog-post">
                                <div className="post-content">
                                    <div className="col-md-12 ">
                                        <h3>Mediquotes.us Terms of use</h3>
                                        <div>
                                            <div>
                                                <h6> 1. ACCEPTANCE OF TERMS OF USE</h6>
                                                <p>Please review these Terms of Use (together with our Privacy Policy,
                                                    the "Agreement"). , (" ", "mediquotes.us", "we" or "us"), is the
                                                    owner and operator of the mediquotes.us website (together with all
                                                    content, tools and services provided on or in connection with such
                                                    website, the "Website"). You must be a resident of the United States
                                                    (including its territorial possessions) and at least eighteen (18)
                                                    years of age to access and use the Website. By accessing, browsing
                                                    or using this Website, or any page thereof, through any direct or
                                                    indirect means, or by using the tools, forms or services offered in
                                                    or through the Website, you accept and agree to be legally bound by
                                                    these Terms of Use and the Agreement. THIS AGREEMENT CONTAINS AN
                                                    AGREEMENT TO ARBITRATE ALL CLAIMS, A PROHIBITION OF CLASS AND
                                                    REPRESENTATIVE ACTIONS AND NON-INDIVIDUALIZED RELIEF, AND CONTAINS
                                                    DISCLAIMERS OF WARRANTIES AND LIABILITY (Sections 5 and 8). These
                                                    provisions form an essential basis of our bargain. If you do not
                                                    agree to these terms and conditions, you are not authorized to
                                                    access or use the Website and you are to cease accessing or
                                                    otherwise using the Website. For purposes of this Agreement, "you"
                                                    or "your" means the person(s) using the Website and/or any tools,
                                                    forms or services offered in or through the Website. If you are
                                                    using the Website on behalf of an employer or other entity, you
                                                    represent and warrant that you have the authority to bind such
                                                    entity to the Agreement.
                                                    mediquotes.us shall have the right at any time to change or
                                                    discontinue any aspect or feature of the Website and the right to
                                                    modify the terms and conditions applicable to users of the Website,
                                                    including these Terms of Use, or any part hereof. Such changes,
                                                    modification, additions or deletions shall be effective immediately
                                                    upon posting on the Website. When we post changes to these terms of
                                                    use OR our PRIVACY POLICY, we will revise the "last updated" date at
                                                    the top of the applicable document. Accordingly, you should
                                                    periodically check the "last updated" date at the top of each
                                                    document so that you can familiarize yourself with any changes. Any
                                                    use by you of the Website or the tools, forms or services offered in
                                                    or through the Websites following such posting shall be deemed to
                                                    constitute acceptance of such changes.
                                                </p>
                                                <br/>
                                                <h6>2. PRIVACY POLICY</h6>
                                                <p>Please review our Privacy Policy, which is hereby incorporated by
                                                    reference into this Agreement, for detailed information about our
                                                    policies and practices applicable to the information that is
                                                    collected through the Website or received directly from you,
                                                    including the sharing of such information with third parties and its
                                                    use for advertising and other purposes. By accessing or using the
                                                    Website, you consent to the collection, storage, use and disclosure
                                                    of your information as described in the Privacy Policy (as it may be
                                                    changed from time to time, as described more fully in the Privacy
                                                    Policy).</p>
                                                <br/>
                                                <h6>3. MATCHING TOOLS AND SERVICES</h6>
                                                <p>Our Website provides tools and services to help you find insurance
                                                    coverage or quotes for insurance coverage, all of which are provided
                                                    by third parties unaffiliated with mediquotes.us (collectively,
                                                    "Insurers"). "Insurers" include insurance companies, mediquotes.us's
                                                    marketing partners, and affiliates or finders working on behalf of
                                                    insurance companies, with whom we may share your information (as
                                                    described in our Privacy Policy) for the purpose of enabling you to
                                                    find affordable insurance coverage. You may be able to access these
                                                    products and services when you complete a request or submit your zip
                                                    code or other information and either (i) get matched with potential
                                                    Insurers who will respond with quotes or conditional offers for
                                                    products or services, or (ii) get provided with the name and
                                                    information about potential Insurers who may able to respond with
                                                    the requested products or services or information about the
                                                    requested products or services without any quotes or offers. The
                                                    Insurers that can provide you with insurance quotes or conditional
                                                    offers may not respond with offers until they obtain additional
                                                    information from you. You may not be matched with the Insurer making
                                                    any specific quote or offer.
                                                    Although mediquotes.us is compensated by its network of Insurers
                                                    when we display advertisements for their products to you and/or when
                                                    you respond to a quote or offer for insurance or another product
                                                    from a Insurer on our Website, mediquotes.us is not a provider of
                                                    insurance, is not acting as an Insurer or broker, and does not make
                                                    any decisions in connection with the underwriting or offering of any
                                                    insurance products. Any compensation from Insurers is payment for
                                                    mediquotes.us's services, tools and facilities. Your use of the
                                                    Website and/or mediquotes.us's services constitutes your agreement
                                                    with this compensation arrangement.
                                                    mediquotes.us does not endorse or recommend the products of any
                                                    particular Insurer. Nor is mediquotes.us an agent of you or any
                                                    Insurer. Our services are only administrative. You should rely on
                                                    your own judgment in deciding which available insurance policy or
                                                    other product, terms and Insurer best suits your needs and financial
                                                    means. The Insurer is solely responsible for its products and
                                                    services, and you agree that mediquotes.us shall not be liable for
                                                    any damages or costs of any type arising out of or in any way
                                                    connected with your use of such products or services. You understand
                                                    that Insurers may keep your insurance request information and any
                                                    other information provided by mediquotes.us or received by them in
                                                    the processing of your request, whether or not you are qualified for
                                                    insurance with them or if you obtain insurance from them. You agree
                                                    to notify any particular Insurer directly if you no longer want to
                                                    receive communications from them. mediquotes.us is not responsible
                                                    for any errors or delays in responding to a request or referral form
                                                    caused by, including but not limited to, an incorrect email address
                                                    provided by you or other technical problems beyond our reasonable
                                                    control.
                                                    The Website and the products and services provided or advertised on
                                                    the Website may not be available in all states, and the availability
                                                    of such services and the products offered on the Website may change
                                                    from to time without notice. Insurers are not attempting to offer
                                                    insurance outside of their authorized states or country by
                                                    participating in and offering their products on the Website.
                                                    Insurers may discontinue, suspend or terminate the offering of any
                                                    insurance or other product in any specific state through the Website
                                                    at any time, without prior notice.
                                                    The information provided by whether on this website or discussed
                                                    during any call does not represent a guarantee of coverage or
                                                    specific payment. All insurance plans are subject to the terms,
                                                    conditions and limitations of the specific policy issued, including,
                                                    but not limited to, eligibility, pre-certification, exclusions and
                                                    limitations, and medical necessity requirements. For further
                                                    information on a policy and its limitations, please call .
                                                    The data and other information you may provide mediquotes.us is not,
                                                    and is not treated as, an application for insurance or a request to
                                                    be pre-approved, pre-qualified or any similar concept. mediquotes.us
                                                    does not guarantee acceptance into any particular insurance program
                                                    or specific terms or conditions with any Insurer; insurance approval
                                                    standards are established and maintained solely by individual
                                                    Insurers. Likewise, mediquotes.us does not guarantee that the
                                                    insurance terms or rates offered and made available by Insurers are
                                                    the best terms or lowest rates available in the market. An Insurer's
                                                    offer may be subject to market conditions, approval and
                                                    qualification (including review of your driving record). The rates
                                                    and fees actually provided by Insurers may be higher or lower
                                                    depending on your complete profile and other considerations used by
                                                    Insurers, such as your driving record. Unless expressly stated in
                                                    writing, nothing contained herein shall constitute an offer or
                                                    promise for an insurance commitment or a rate lock-in agreement.
                                                    Insurers may not offer all products as well as not offer products in
                                                    all states. You might not be matched with the Insurer making any
                                                    specific offer. To help the government fight identity theft, the
                                                    funding of terrorism and money laundering activities, and to help
                                                    attempt to verify your identity, Insurers may obtain, verify and
                                                    record information that identifies each person who applied for or
                                                    obtain insurance from them. For example, they may ask for your name,
                                                    Social Security Number or driver's license number, address, date of
                                                    birth, and other important information, that will allow proper
                                                    identification of you. During the Insurer application process, they
                                                    may also ask to see additional information. mediquotes.us does not
                                                    charge you a fee for its service in matching you with Insurers.
                                                    By clicking on any button indicating an acceptance or agreement to
                                                    terms, a continuance of processing, a request for quotes or any
                                                    other submission ("submission"), you understand that you are
                                                    agreeing to the stated terms and conditions of that submission and
                                                    that you are submitting an inquiry as to insurance or another
                                                    product through mediquotes.us to each of the Insurers to whom your
                                                    request is transmitted.
                                                    You agree that by providing your telephone number in any submission
                                                    within 's webpages that you are providing your signature expressly
                                                    consenting to receive calls, emails, postal mail, text messages, and
                                                    other forms of communication from up to eight companies such as
                                                    these companies including calls made with an automatic telephone
                                                    dialing system or prerecorded voice to the wireless or residential
                                                    telephone number you provided even if you are on a state or federal
                                                    Do Not Call registry. You acknowledge and understand that your
                                                    wireless carrier may impose charges for these calls or texts and
                                                    consent to receive communications is not a condition of purchase.
                                                    You may revoke your consent at any time.
                                                    By including your email in any submission, you are extending an
                                                    express invitation to each Insurer you have been matched with to
                                                    contact you by email at any email address you provided (in this
                                                    case, any such email from an Insurer will not be considered spam or
                                                    unauthorized by any local, state or federal law or regulation). For
                                                    any service, you represent that all of the information you have
                                                    provided in your submission is true and complete.
                                                    mediquotes.us seeks to provide you multiple insurance quotes through
                                                    its network of unaffiliated Insurers. By submitting information on
                                                    an insurance request form or similar form, you authorize us to
                                                    provide such information to Insurers in order to help you complete
                                                    your request. Additionally, by submitting a request for insurance,
                                                    you authorize the Insurers to verify the accuracy and authenticity
                                                    of all information supplied by you, both internally and with the
                                                    assistance of unaffiliated third parties.
                                                    To determine which Insurers may be matched with you, Insurers
                                                    provide to us criteria about the type of insurance and the type of
                                                    customer (for example, state of residence) in which the Insurer is
                                                    interested. mediquotes.us will provide your information to Insurers
                                                    whose criteria match your profile. Where it is discovered that you
                                                    do not meet certain Insurers' criteria or you do not qualify for
                                                    their products, you also authorize us to provide your information to
                                                    other Insurers whose criteria match your profile and whose products
                                                    we feel might be of help in completing your request.
                                                </p>
                                                <br/>
                                                <h6>4. PROPRIETARY RIGHTS AND LICENSES</h6>
                                                <p>You are granted a limited, non-transferable, non-sublicensable,
                                                    non-exclusive, revocable license to use and display the contents of
                                                    the Website on a single computer solely for your personal use (and
                                                    not for commercial resale or redistribution) to find insurance and
                                                    other products or services featured on the Website. All other rights
                                                    are hereby reserved. "mediquotes.us" and the mediquotes.us logo are
                                                    service marks owned by . Other product and company names mentioned
                                                    herein, including the names of Insurers, may be the trademarks of
                                                    their respective owners. Nothing on the Website should be construed
                                                    as granting, by implication, estoppel or otherwise, any license or
                                                    right to use any service mark or trademark (individually and
                                                    collectively, "Mark" or "Marks") displayed on the Website, without
                                                    the prior written permission of mediquotes.us or the applicable Mark
                                                    holder specific for each such use. The Marks may not be used to
                                                    disparage mediquotes.us, the Insurers, the applicable third party or
                                                    the products or services of such parties, nor shall they be used in
                                                    any manner that may damage any goodwill in the Marks.
                                                    The Website contains copyrighted material, trademarks and other
                                                    proprietary information of mediquotes.us and/or its Insurers,
                                                    including, but not limited to, text, software, photos, video,
                                                    graphics, music and sound, and the entire contents of the Website
                                                    are copyrighted as a collective work under the United States
                                                    copyright laws. mediquotes.us owns a copyright in the selection,
                                                    coordination, arrangement and enhancement of such content, as well
                                                    as in the content original to it. You may not modify, publish,
                                                    transmit, participate in the transfer or sale of, create derivative
                                                    works of, or in any way exploit, any of the content, in whole or in
                                                    part. Nor may you reverse engineer, decompile or disassemble, or
                                                    make any derivative works based on, the Website or the software or
                                                    technology used in the Website (including any customization,
                                                    translation, or localization). Except as otherwise expressly
                                                    permitted under copyright law, no copying, redistribution,
                                                    retransmission, publication or commercial or non-commercial
                                                    exploitation of material from the Website will be permitted without
                                                    the express written permission of mediquotes.us and the copyright
                                                    owner (if different from mediquotes.us). Elements of the Website are
                                                    protected by trade dress, trademark, unfair competition, and other
                                                    state and federal laws and may not be copied or imitated in whole or
                                                    in part, by any means, including but not limited to, the use of
                                                    framing or mirrors.
                                                    We grant you a revocable right to create a link to the home page of
                                                    this Website. You will not state or imply that you or any website on
                                                    which you place such a link, or any of its pages, are endorsed by,
                                                    sponsored by or affiliated or associated with this Website or
                                                    mediquotes.us. Nor may you make any representations or warranties,
                                                    express or implied, with respect to this Website or any products or
                                                    services available hereon, or any statements that are inconsistent
                                                    with the Agreement.
                                                </p>
                                                <br/>
                                                <h6>5. DISCLAIMERS AND LIMITATIONS OF LIABILITY</h6>
                                                <p>mediquotes.us intends that the information contained in the Website
                                                    be accurate and reliable; however, errors sometimes occur. In
                                                    addition, changes and improvements to the information provided
                                                    herein may be made by mediquotes.us at any time. Under no
                                                    circumstances will mediquotes.us be liable for any loss or damage
                                                    caused by your reliance on information obtained through the Website.
                                                    It is your responsibility to evaluate the accuracy, completeness or
                                                    usefulness of any information, opinion, advice or other content
                                                    available through the Website.
                                                    THE WEBSITE AND THE INFORMATION, SOFTWARE, PRODUCTS AND SERVICES
                                                    ASSOCIATED WITH IT ARE PROVIDED "AS IS." mediquotes.us AND/OR ITS
                                                    INSURERS DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR
                                                    IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO THE WEBSITE AND ANY
                                                    INFORMATION, SOFTWARE, PRODUCTS AND SERVICES PROVIDED HEREIN,
                                                    INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
                                                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY,
                                                    FREEDOM FROM VIRUSES AND NONINFRINGEMENT. USE OF THE WEBSITE AND/OR
                                                    mediquotes.us's SERVICES IS AT YOUR OWN RISK. mediquotes.us AND/OR
                                                    ITS INSURERS AND SUPPLIERS ARE NOT LIABLE FOR ANY DIRECT, INDIRECT,
                                                    PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER
                                                    INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR THE
                                                    INABILITY TO USE THE WEBSITE AND/OR mediquotes.us's SERVICES OR WITH
                                                    THE DELAY OR INABILITY TO USE THE WEBSITE, OR FOR ANY INFORMATION,
                                                    SOFTWARE, PRODUCTS AND SERVICES OBTAINED THROUGH THE WEBSITE
                                                    (INCLUDING BUT NOT LIMITED TO RELIANCE BY YOU ON ANY INFORMATION
                                                    OBTAINED AT THE WEBSITE), OR THAT RESULT FROM MISTAKES, OMISSIONS,
                                                    INTERRUPTIONS, ERRORS, DEFECTS, VIRUSES, WORMS, TROJAN HORSES, OR
                                                    OTHER HARMFUL CODE OR COMPUTER PROGRAMMING ROUTINES, WHETHER
                                                    RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS
                                                    BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
                                                    mediquotes.us AND/OR ITS INSURERS HAVE BEEN ADVISED OF THE
                                                    POSSIBILITY OF DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE
                                                    EXCLUSION OF IMPLIED WARRANTIES AND CERTAIN LIMITATIONS OF
                                                    LIABILITY, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                                                </p>
                                                <br/>
                                                <h6>6. INDEMNITY</h6>
                                                <p>As a condition of use of the Website and/or mediquotes.us's services,
                                                    you agree to indemnify mediquotes.us and its affiliates, suppliers
                                                    and Insurers, and its and their respective members, managers,
                                                    directors, officers and employees, from and against any and all
                                                    liabilities, expenses (including reasonable attorneys' fees), claims
                                                    and damages arising out of your use of the Website, including
                                                    without limitation any claims alleging facts that if true would
                                                    constitute a breach by you of the Agreement.</p>
                                                <br/>
                                                <h6>7. LINKS TO THIRD-PARTY WEBSITES</h6>

                                                <p>The Website may contain links to websites maintained by third
                                                    parties. Such links are provided for your convenience and reference
                                                    only. mediquotes.us does not operate or control in any respect any
                                                    information, software, products or services available on such
                                                    websites. mediquotes.us's inclusion of a link to a website does not
                                                    imply any endorsement of the services or the website, its contents,
                                                    or its sponsoring organization. mediquotes.us is not responsible for
                                                    the accuracy or content of the information provided by any
                                                    third-party website, nor is it liable for any direct or indirect
                                                    technical or system issues arising out of your access to or use of
                                                    technologies or programs available through that website.</p>
                                                <br/><h6>
                                                8. AGREEMENT TO ARBITRATE</h6>
                                                <p>This arbitration agreement includes claims related to our clients,
                                                    vendors, and Marketing Partners, which are third party beneficiaries
                                                    of this arbitration agreement. You and we each agree that any and
                                                    all disputes or claims that relate to or arise from your use of or
                                                    access to our Services, or any products or services sold, offered,
                                                    or purchased through our Services shall be resolved exclusively
                                                    through final and binding arbitration, rather than in court, except
                                                    that you may assert claims in small claims court, if your claims
                                                    qualify. The Federal Arbitration Act governs the interpretation and
                                                    enforcement of this Agreement to Arbitrate section (this "Agreement
                                                    to Arbitrate").
                                                    Prohibition of Class and Representative Actions and
                                                    Non-Individualized Relief
                                                    You and we agree that each of us may bring claims against the other
                                                    only on an individual basis and not as a plaintiff or class member
                                                    in any purported class or representative action or proceeding,
                                                    including but not limited to actions under the Telephone Consumer
                                                    Protection Act, 47 U.S.C. § 227 et seq. Unless both you and us agree
                                                    otherwise, the arbitrator may not consolidate or join more than one
                                                    person's or party's claims, and may not otherwise preside over any
                                                    form if a consolidated, representative, or class proceeding. The
                                                    arbitrator may award relief, including monetary, injunctive, and
                                                    declaratory relief, only in favor of the individual party seeking
                                                    relief and only to the extent necessary to provide relief
                                                    necessitated by that party's individual claim(s). Any relief awarded
                                                    cannot affect our other users.
                                                    Arbitration Procedures
                                                    The arbitrator, and not any federal, state, or local court or
                                                    agency, shall have exclusive authority to resolve any dispute
                                                    arising out of or relating to the interpretation, applicability,
                                                    enforceability or formation of this Agreement to Arbitrate, any part
                                                    of it, or of this Agreement including, but not limited to, any claim
                                                    that all or any part of the Agreement to Arbitrate or this Agreement
                                                    is void or voidable.
                                                    The arbitration will be conducted by the American Arbitration
                                                    Association ("AAA") under its rules and procedures, including the
                                                    AAA's Supplementary Procedures for Consumer-Related Disputes (as
                                                    applicable), as modified by this Agreement to Arbitrate. The AAA's
                                                    rules and a form for initiating arbitration proceedings is available
                                                    on the AAA's site at http://www.adr.org.
                                                    The arbitration shall be held in the county in which you reside or
                                                    at another mutually agreed location. If the value of the relief
                                                    sought is $10,000 or less, you or we may elect to have the
                                                    arbitration conducted by telephone or based solely on written
                                                    submissions, which election shall be binding on you and us subject
                                                    to the arbitrator's discretion to require an in-person hearing, if
                                                    the circumstances warrant. Attendance at an in-person hearing may be
                                                    made by telephone by you and/or us, unless the arbitrator requires
                                                    otherwise.
                                                    The arbitrator will decide the substance of all claims in accordance
                                                    with the laws of the State of Delaware, including recognized
                                                    principles of equity, and will honor all claims of privilege
                                                    recognized by law. The arbitrator shall not be bound by rulings in
                                                    prior arbitrations involving our other users, but is bound by
                                                    rulings in prior arbitrations involving the same user to the extent
                                                    required by applicable law. The arbitrator's award shall be final
                                                    and binding and judgment on the award rendered by the arbitrator may
                                                    be entered in any court having jurisdiction thereof.
                                                    This arbitration provision shall survive termination of this
                                                    Agreement to Arbitrate and the closing of your Account or your use
                                                    of or access to our Services, or any products or services sold,
                                                    offered, or purchased through our Services.
                                                    Costs of Arbitration
                                                </p>
                                                <p>Payment of all filing, administration and arbitrator fees will be
                                                    governed by the AAA's rules, unless otherwise stated in this
                                                    Agreement to Arbitrate. If the value of the relief sought is $10,000
                                                    or less, at your request, we will pay all filing, administration,
                                                    and arbitrator fees associated with the arbitration. Any request for
                                                    payment of fees by us should be submitted by mail to the AAA along
                                                    with your Demand for Arbitration and we will make arrangements to
                                                    pay all necessary fees directly to the AAA. If the value of the
                                                    relief sought is more than $10,000 and you are able to demonstrate
                                                    that the costs of arbitration will be prohibitive as compared to the
                                                    costs of litigation, we will pay as much of the filing,
                                                    administration, and arbitrator fees as the arbitrator deems
                                                    necessary to prevent the arbitration from being cost-prohibitive. In
                                                    the event the arbitrator determines the claim(s) you assert in the
                                                    arbitration to be frivolous, you agree to reimburse us for all fees
                                                    associated with the arbitration paid by us on your behalf, which you
                                                    otherwise would be obligated to pay under the AAA's rules.
                                                    Costs of Arbitration
                                                </p>
                                                <p>Payment of all filing, administration and arbitrator fees will be
                                                    governed by the AAA's rules, unless otherwise stated in this
                                                    Agreement to Arbitrate. If the value of the relief sought is $10,000
                                                    or less, at your request, we will pay all filing, administration,
                                                    and arbitrator fees associated with the arbitration. Any request for
                                                    payment of fees by us should be submitted by mail to the AAA along
                                                    with your Demand for Arbitration and we will make arrangements to
                                                    pay all necessary fees directly to the AAA. If the value of the
                                                    relief sought is more than $10,000 and you are able to demonstrate
                                                    that the costs of arbitration will be prohibitive as compared to the
                                                    costs of litigation, we will pay as much of the filing,
                                                    administration, and arbitrator fees as the arbitrator deems
                                                    necessary to prevent the arbitration from being cost-prohibitive. In
                                                    the event the arbitrator determines the claim(s) you assert in the
                                                    arbitration to be frivolous, you agree to reimburse us for all fees
                                                    associated with the arbitration paid by us on your behalf, which you
                                                    otherwise would be obligated to pay under the AAA's rules.
                                                    Confidentiality
                                                </p>
                                                <p>The arbitration proceedings and arbitration award shall be maintained
                                                    by the parties as strictly confidential, except as is otherwise
                                                    required by court order or as is necessary to confirm, vacate or
                                                    enforce the award and for disclosure in confidence to the parties'
                                                    respective attorneys, tax advisors and senior management and to
                                                    family members of a party who is an individual.
                                                    Severability
                                                    With the exception of any of the provisions in of this Agreement to
                                                    Arbitrate ("Prohibition of Class and Representative Actions and
                                                    Non-Individualized Relief"), if an arbitrator or court decides that
                                                    any part of this Agreement to Arbitrate is invalid or unenforceable,
                                                    the other parts of this Agreement to Arbitrate shall still apply. If
                                                    an arbitrator or court decides that any of the provisions in Section
                                                    1 of this Agreement to Arbitrate ("Prohibition of Class and
                                                    Representative Actions and Non-Individualized Relief") is invalid or
                                                    unenforceable, then the entirety of this Agreement to Arbitrate
                                                    shall be null and void. The remainder of the Agreement and its Legal
                                                    Disputes Section will continue to apply.
                                                </p>
                                                <br/>
                                                <h6>9. WEBSITE PROVIDED FROM THE UNITED STATES</h6>
                                                <p>If you are using the Website from outside the United States, please
                                                    be aware that your information will be transferred to, stored and
                                                    processed in the United States where our servers are located and our
                                                    central database is operated. The data protection and other laws of
                                                    the United States and other countries might not be as comprehensive
                                                    as those in your country. By using the Website, which is provided
                                                    exclusively from the United States, you hereby consent to the
                                                    transfer of your information to the United States and its
                                                    collection, storage, sharing and use as described in our Privacy
                                                    Policy. You further agree that all transactions relating to the
                                                    Website shall be deemed to have occurred in the United States. Those
                                                    who access or use the Website from other jurisdictions do so at
                                                    their own volition and are responsible for compliance with all local
                                                    laws, statutes, rules, regulations and codes.</p>
                                                <br/><h6>10. OTHER TERMS</h6>
                                                <p>This Agreement (which hereby incorporates by reference any other
                                                    provisions applicable to use of the Website, including, but not
                                                    limited to, any supplemental terms governing the use of certain
                                                    specific material contained in the Websites) constitutes the entire
                                                    agreement between you and mediquotes.us with respect to the Website
                                                    and it supersedes all prior or contemporaneous communications,
                                                    promises and proposals, whether oral, written or electronic, between
                                                    you and mediquotes.us with respect to the Website and information,
                                                    software, products and services associated with it. A printed
                                                    version of this Agreement and of any notice given in electronic form
                                                    shall be admissible in judicial or administrative proceedings based
                                                    upon or relating to this Agreement to the same extent and subject to
                                                    the same conditions as other business documents and records
                                                    originally generated and maintained in printed form. All terms of
                                                    the Agreement shall survive any termination hereof, except for those
                                                    terms (like licenses) that are revocable or, by their context, are
                                                    not intended to survive termination.
                                                    We shall not be responsible for any errors, failure to perform or
                                                    delay in performance of any obligation hereunder due to labor
                                                    disturbances, accidents, fires, floods, telecommunications or
                                                    Internet failures, strikes, wars, riots, rebellions, blockades, acts
                                                    of government, governmental requirements and regulations or
                                                    restrictions imposed by law, acts or failures of third-party service
                                                    providers or other third parties, or any other similar conditions
                                                    beyond our reasonable control. You agree that no joint venture,
                                                    partnership, employment or agency relationship exists between you
                                                    and mediquotes.us or any of our affiliates as a result of this
                                                    Agreement or your use of the Website. Neither the course of conduct
                                                    between the parties nor trade practice shall act to modify any
                                                    provision of this Agreement.
                                                    All notices, demands and other communications hereunder shall be in
                                                    writing and shall be effective upon receipt, provided that we may
                                                    provide notice to you by posting announcements on the Website or
                                                    sending an e-mail to you at any e-mail address you have on file with
                                                    us. Any such e-mail notice to you will be deemed given on the day it
                                                    is sent. Except as specified in the next sentence, all notices to us
                                                    of a legal nature shall be in writing and shall be sent by certified
                                                    first-class U.S. mail, return receipt requested, to: , LLC, 1035 NE
                                                    125th ST, Suite 310, North Miami, FL 33161. All notices regarding
                                                    copyright or other intellectual property infringement issues shall
                                                    be sent in accordance with our intellectual property policy
                                                    described in the section entitled "Copyright and Other Intellectual
                                                    Property Infringement" below. If you have any questions about the
                                                    Agreement, or if you need to notify us about a non-legal matter,
                                                    then contact us through the "Contact Us" form on the Website.
                                                    This Agreement is not assignable by you, except by operation of law;
                                                    mediquotes.us may assign its rights under this Agreement without
                                                    condition. This Agreement will be binding upon and will inure to the
                                                    benefit of the parties, their successors and permitted assigns.
                                                </p>
                                                <br/><h6>11. COPYRIGHT AND OTHER INTELLECTUAL PROPERTY INFRINGEMENT</h6>
                                                <p>As mediquotes.us asks others to respect its intellectual property
                                                    rights, it respects the intellectual property rights of others, and
                                                    requires its users to do so. If you are a copyright owner or an
                                                    agent thereof and believe that any third-party content on the
                                                    Website infringes upon your copyrights, you may submit a
                                                    notification pursuant to the Digital Millennium Copyright Act
                                                    ("DMCA") by providing our Copyright Agent with the following
                                                    information in writing (see 17 U.S.C § 512(c)(3) for further
                                                    detail):
                                                    A physical or electronic signature of a person authorized to act on
                                                    behalf of the owner of an exclusive right that is allegedly
                                                    infringed;
                                                    Identification of the copyrighted work claimed to have been
                                                    infringed, or, if multiple copyrighted works on or available through
                                                    our Website are covered by a single notification, a representative
                                                    list of such works at those locations;
                                                    Identification of the material that is claimed to be infringing or
                                                    to be the subject of infringing activity and that is to be removed
                                                    or access to which is to be disabled and information reasonably
                                                    sufficient to permit mediquotes.us to locate the material;
                                                    Information reasonably sufficient to permit us to contact you, such
                                                    as an address, telephone number, and, if available, an electronic
                                                    mail address; A statement that you have a good faith belief that use
                                                    of the material in the manner complained of is not authorized by the
                                                    copyright owner, its agent, or the law; and
                                                    A statement that the information in the notification is accurate,
                                                    and under penalty of perjury, that you are authorized to act on
                                                    behalf of the owner of an exclusive right that is allegedly
                                                    infringed.
                                                    mediquotes.us's designated Copyright Agent to receive notifications
                                                    of claimed infringement is Copyright Agent, address: 1035 NE 125th
                                                    ST, Suite 310, North Miami, FL 33161. For clarity, only DMCA notices
                                                    should go to the Copyright Agent; any other feedback, comments,
                                                    requests for technical support, and other communications should be
                                                    directed to mediquotes.us customer service. You acknowledge that if
                                                    you fail to comply with all of the requirements of this Section,
                                                    your DMCA notice may not be valid.
                                                    In the event that you believe mediquotes.us removed any of your
                                                    content in response to a false notice of copyright infringement, you
                                                    should notify mediquotes.us promptly in writing pursuant to the
                                                    DMCA, at the mailing address provided above. Such notification
                                                    should include:
                                                    Your physical or electronic signature;
                                                    Identification of the content that has been removed or to which
                                                    access has been disabled and the location at which the content
                                                    appeared before it was removed or disabled;
                                                    A statement that you have a good faith belief that the content was
                                                    removed or disabled as a result of mistake or a misidentification of
                                                    the content; and
                                                    Your name, address, telephone number, and e-mail address, a
                                                    statement that you consent to the jurisdiction of the state of
                                                    Delaware, and a statement that you will accept service of process
                                                    from the person who provided notification of the alleged
                                                    infringement.
                                                    Furthermore, you should include a clear statement of your acceptance
                                                    of process pertaining to the notifying party or the agent of the
                                                    party as defined in DMCA § 512(c)(1)(C). If a counter-notice is
                                                    received by the Copyright Agent, mediquotes.us may send a copy of
                                                    the counter-notice to the original complaining party informing that
                                                    person that it may replace the removed content or cease disabling it
                                                    in ten (10) business days. Unless the copyright owner files an
                                                    action seeking a court order against the content provider or user,
                                                    the removed content may be replaced, or access to it restored, in
                                                    ten (10) to fourteen (14) business days or more after receipt of the
                                                    counter-notice, at our sole and absolute discretion.
                                                    mediquotes.us has a policy of terminating the account of, or denying
                                                    access or use of the Website to, in its sole and absolute
                                                    discretion, any user who repeatedly infringes the copyrights or
                                                    other intellectual property rights of others.
                                                    If you believe that any of your intellectual property rights other
                                                    than copyrights have been infringed (for example, if you believe
                                                    that any product listed on the Site is infringing your trademarks),
                                                    please e-mail mediquotes.us at info@mediquotes.us. mediquotes.us
                                                    reserves the right, in its sole and absolute discretion, to suspend
                                                    or terminate the account of any user who infringes the intellectual
                                                    property rights of mediquotes.us or others, and/or to remove,
                                                    delete, edit or disable access to such person's User Content.
                                                    mediquotes.us shall have no liability for any action taken pursuant
                                                    to this Section.
                                                </p>
                                                <br/><h6>12. VOICE RECORDING AND MONITORING CONSENT</h6>
                                                <p>You agree and consent that may record or monitor oral or voice
                                                    communications, utterances, and conversations or commands made by
                                                    you during any calls you make to as permitted by 18 U.S.C. §§ 2511
                                                    et seq., Cal. Penal Code § 632 et seq., and other state laws that
                                                    regulate call monitoring and recording. By calling , you expressly
                                                    consent and grant it the right to record or monitor and use this
                                                    information in connection with RSI and for the purposes set out in
                                                    the Privacy Policy. is under no obligation to monitor or record its
                                                    services for accuracy, completeness, or quality.</p>
                                                <br/><h6>13. HEALTH INFORMATION</h6>
                                                <p>The health information on this website is provided by solely for
                                                    informational purposes. It is not meant to provide medical advice
                                                    tailored to you in any way. It does not constitute medical advice
                                                    and is not intended to be a substitute for proper medical care
                                                    provided by a physician. assumes no responsibility for any
                                                    circumstances arising out of the use, misuse, interpretation or
                                                    application of any information supplied on this website. Always
                                                    consult with your doctor for appropriate examinations, treatment,
                                                    testing, and care recommendations. Do not rely on information on
                                                    this site as a tool for self-diagnosis.</p>
                                                <br/><h6>14. POLICY REGARDING USER CONTENT</h6>
                                                <p> provides this website as a benefit to its users, and strives to make
                                                    this online forum safe for all users. has developed the following
                                                    policies regarding the removal of user content from this website. As
                                                    a user of this website, you agree to follow and to be bound by these
                                                    policies.
                                                    may remove content it deems, in its sole discretion, to be
                                                    inappropriate, harmful, or violates the rights of others from this
                                                    website including, but not limited to:
                                                    content that threatens or promotes injury to others or self
                                                    content that threatens or promotes violence to any person or
                                                    property
                                                    content that threatens or promotes criminal conduct or unlawful
                                                    activity
                                                    content used to harass, bully, blackmail, or disparage another
                                                    content containing hate speech
                                                    content containing nudity or graphic depictions of sex or violence
                                                    content that is harmful to this website, to this online forum, to ,
                                                    or to the goodwill associated with any of 's trademarks
                                                    content that violates the rights of another, including, without
                                                    limitation, contractual rights, intellectual property rights and
                                                    rights of publicity and privacy
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
