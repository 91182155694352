import React from 'react';
import Footer from "./Footer";
import {Link} from "react-router-dom";

export default function PrivacyPolicy() {
    var BreadCrumbTitle = "Privacy Policy";
    document.title = BreadCrumbTitle;
    return (
        <>
            <header id="home" className="hero-area-2">
                <div className="overlay"></div>
                <div className="navigation">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg">
                            <div className="navbar-brand"><Link to="/"><i
                                className="lni lni-pulse css-1n3ub8g"></i>Mediquotes</Link></div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <Link to="/#home">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/#features">Benefits</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="contents text-center">
                                <h2>INSURANCE QUOTE</h2>
                                <p>Our most comprehensive standardized Medigap insurance <br className="d-none"/>
                                    plans carries additional benefits</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div id="blog-single">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10">
                            <div className="blog-post">
                                <div className="post-content">
                                    <div className="col-md-12 ">
                                        <h3>PRIVACY POLICY</h3>


                                        <p>This privacy statement describes how mediquotes.com collects and uses the
                                            personal information you provide on our Web site: mediquotes.us. It also
                                            describes the choices available to you regarding our use of your personal
                                            information and how you can access and update this information. Collection
                                            and Use of Personal Information We collect the following personal
                                            information from you</p>
                                        <ul className="custom-bullets">
                                            <li>Contact Information such as name, email address, mailing address, phone
                                                number
                                            </li>
                                            <li>As is true of most Web sites, we automatically gather information about
                                                your computer such as your IP address, browser type, referring/exit
                                                pages, and operating system. We use this information to Send you quote
                                                confirmation
                                            </li>
                                            <li>Respond to customer service requests</li>
                                            <li>Administer your account</li>
                                            <li>Respond to your questions and concerns</li>
                                            <li>when we believe in good faith that disclosure is necessary to protect
                                                our rights, protect your safety or the safety of others, investigate
                                                fraud, or respond to a government request,
                                            </li>
                                            <li>if mediquotes.us is involved in a merger, acquisition, or sale of all or
                                                a portion of its assets, you will be notified via email and/or a
                                                prominent notice on our Web site of any change in ownership or uses of
                                                your personal information, as well as any choices you may have regarding
                                                your personal information.
                                                to any other third party with your prior consent to do so.
                                            </li>
                                        </ul>
                                        <br/>
                                        <h3>Cookies and Other Tracking Technologies</h3>


                                        <p>We may use cookies, for example, to keep track of your preferences and
                                            profile information. Cookies are also used to collect general usage and
                                            volume statistical information that does not include personal information.
                                            We may use another company to place cookies on your computer to collect
                                            non-personally identifiable information to compile aggregated statistics for
                                            us about visitors to our site. Web beacons Our Web pages may also contain
                                            electronic images known as Web beacons (sometimes called single-pixel gifs)
                                            that are set by our third-party partners. Web beacons are used along with
                                            cookies enabling our partners to compile aggregated statistics to analyze
                                            how our site is used. Links to Other Web Sites Our Site includes links to
                                            other Web sites whose privacy practices may differ from those of
                                            mediquotes.us. If you submit personal information to any of those sites,
                                            your information is governed by their privacy statements. We encourage you
                                            to carefully read the privacy statement of any Web site you visit. Security
                                            he security of your personal information is important to us. When you enter
                                            sensitive information (such as credit card number) on our order forms, we
                                            encrypt the transmission of that information using secure socket layer
                                            technology (SSL). We follow generally accepted industry standards to protect
                                            the personal information submitted to us, both during transmission and once
                                            we receive it. No method of transmission over the Internet, or method of
                                            electronic storage, is 100% secure, however. Therefore, we cannot guarantee
                                            its absolute security. If you have any questions about security on our Web
                                            site, you can contact us at support@mediquotes.us Notification of Privacy
                                            Statement Changes We may update this privacy statement to reflect changes to
                                            our information practices. If we make any material changes we will notify
                                            you by email (sent to the e-mail address specified in your Submitions) or by
                                            means of a notice on this Site prior to the change becoming effective. We
                                            encourage you to periodically review this page for the latest information on
                                            our privacy practices.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
