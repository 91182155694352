import {useRef, useState} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

import Footer from "./Footer";
import {Link} from 'react-router-dom';

export default function Home() {
    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const formData = ({name, phone, email, subject, message})
    const [formResponse, setFormResponse] = useState('');
    const lead_idRef = useRef(null);

    const handleSubmit = async () => {
        const lead_id = lead_idRef.current.value;
        if (lead_id == '') {
            //handleSubmit();
            return false;
        }

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "X-Requested-With": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Access-Control-Allow-Credentials": true,
        };

        const data = {
            "host": "mediquotes",
            "first_name": name,
            "last_name": lname,
            "email": email,
            "phone": phone,
            "company": '',
            "address": '',
            "state": state,
            "zipcode": zipcode,
            "dob": "", // "lead_id": document.getElementById('leadid_token').value,
            "lead_id": lead_id,
            "ip_address": '',
            "remarks": message
        };
        console.log("Form DATA: " + JSON.stringify(data));

        const url = 'https://mediquotes.us/process_api.php';
        // const url = 'http://localhost/mediquotes.us.reactjs/public/process_api.php';

        const result = await axios.post(url, data, {
            headers: headers
        });

        console.log(result.data.status);
        if (result.data.status == 'fail') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-danger">' + result.data.message + '</div>';
        }
        if (result.data.status == 'success') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-success">' + result.data.message + '</div>';
            const timeout = setTimeout(() => {
                const cookies = Cookies.get();
                const cookieKeys = Object.keys(cookies).filter((key) => key.startsWith("leadid_token-"));
                for (let index = 0; index < cookieKeys.length; index++) {
                    Cookies.remove(cookieKeys[index]);
                }

                window.location.reload();
            }, 1000);
        }
    };

    function AutoSubmit() {
        //alert(document.getElementById("phone_number").value);
    }

    return (<>
        <header id="home" className="hero-area-2">
            <div className="overlay"></div>
            <div className="navigation">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="/" style={{textAlign: "center"}}><i
                            className="lni lni-pulse css-1n3ub8g"></i>Mediquotes
                            <div style={{fontSize: "50%", color: "white"}}>A non-government entity</div>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation"><span
                            className="toggler-icon"></span> <span className="toggler-icon"></span> <span
                            className="toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="page-scroll" href="#home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="page-scroll" href="#features">Benefits</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="contents text-left" style={{paddingBottom: "30px"}}>
                            <h2>Medicare Advantage Plan Quote</h2>
                            <p>Our licensed insurance agents
                                are standing by to help review your Medicare plan options.</p>
                            <p>We represent MA, MAPD And PDP Plans For [Humana], [UnitedHealthcare®], [Aetna], [Cigna
                                Healthcare], [Wellcare], [Anthem], [Devoted], [Blue Cross Blue Shield of Michigan] and
                                [Kaiser Permanente].</p>
                        </div>
                        <div className="contact-info">
                            <i className="lni-phone"></i>
                            <h5 style={{color: "#fff"}}>Phone</h5>
                            <p style={{color: "#fff"}}>Phone Number: (561) 589 2506<br/>
                                <a href="tel:711">TTY: 711</a></p>
                            <br/>
                            <i className="lni-map"></i>
                            <h5 style={{color: "#fff"}}>Working Hours</h5>
                            <p style={{color: "#fff"}}>10AM - 7PM EST Monday-Friday</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="myform contents" style={{
                            background: "rgba(255,255,255,0.9)",
                            padding: "15px 30px 30px 30px",
                            marginTop: "125px",
                            marginBottom: "10px"
                        }}>
                            <h1 style={{fontSize: "27px", paddingBottom: "0px"}}>Get a no obligation Quote</h1>
                            <span style={{
                                float: "left", fontSize: "14px", color: "#313", marginBottom: "17px", width: "100%"
                            }}>* There is no obligation to enroll!</span>
                            <form onSubmit={handleSubmit} action="#" method="post" className="contact-form"
                                  name='contact-form' id='contact-form'>
                                <input ref={lead_idRef} id="leadid_token" name="universal_leadid" type="hidden"
                                       value=""/>
                                <div className="row ">
                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                        <div className="form-group">
                                            <i className="contact-icon fa fa-phone"></i>
                                            <input type="text" className="form-control capture_number"
                                                   id="phone_number" name="phone_number"
                                                   placeholder="Phone Number" required="" value={phone}
                                                   onChange={e => setPhone(e.target.value)} onKeyUp={AutoSubmit}
                                                   data-error="Please enter your number"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                        <p>
                                            <label style={{fontSize: "12px", fontWeight: "normal", color: "#313"}}>
                                                <input type="hidden" id="leadid_tcpa_disclosure"/>
                                                [By clicking the "Get No Obligation Quote" button below, I
                                                agree to this website's <Link to="/terms-of-use">terms of
                                                service</Link> and <Link to="/privacy">privacy policy</Link>,
                                                and provide my Express Written Consent via this chat / webform for this
                                                company and up to <Link
                                                to="/partners">three third party insurance companies
                                                or their agents or partner companies </Link>
                                                to contact me at the landline or wireless
                                                phone numbers that I provide even if they are listed on any
                                                government Do-Not-Call registries,
                                                in order to send me marketing/promotional messages,
                                                including insurance quotes related to the product or service
                                                I am inquiring about.

                                                I agree to receive communications via live, automated
                                                telephone dialing system, prerecorded messages,
                                                or text messages and acknowledge that consent is not a
                                                condition to purchase goods or services.
                                                Products and services offered on this page include Medicare Supplement,
                                                Medicare Advantage, Prescription Drug insurance plans, and other
                                                health-related services.
                                                Consent provided hereunder can be revoked by emailing
                                                "support@mediquotes.us”.

                                                Participating sales agencies represent Medicare Advantage [HMO, PPO
                                                and PFFS] organizations and stand-alone PDP prescription drug plans
                                                that are contracted with Medicare. Enrollment depends on the plan’s
                                                contract renewal. We do not offer every plan available in your area.
                                                Currently we represent [insert number of organizations]
                                                organizations which offer [insert number of plans] products in your
                                                area. Please contact <a
                                                href="http://Medicare.gov">http://Medicare.gov</a> , 1–800–MEDICARE, or
                                                your local State Health Insurance Program (SHIP) to get information on
                                                all of your options.


                                                Enrollment in the described plan type may be limited to
                                                certain times of the year unless you eligible for a Special
                                                Enrollment Period.
                                                Telephone company may impose additional charges on
                                                subscriber for messages (required for SMS).This is a
                                                solicitation for insurance.
                                                Your information will be provided to a licensed agent for
                                                future contact.]

                                                I understand this request has been initiated by me and is an unscheduled
                                                contact request.
                                                I further understand that this request, initiated by me, is my
                                                affirmative consent to be contacted which is in compliance with all
                                                federal and state telemarketing and Do-Not-Call laws.
                                            </label>
                                        </p>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                        <div className="help-block with-errors"></div>
                                        <button type="button" onClick={handleSubmit}
                                                className="btn btn-common btn-form-submit disabled"
                                                style={{
                                                    pointerEvents: "all", cursor: "pointer", marginBottom: "10px"
                                                }}>Get no obligation Quote
                                        </button>
                                        <div id="ajaxResponse" className=""></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section id="features" className="section">
            <div className="container">
                <div className="section-header">
                    <h2 className="section-title">Medicare Advantage Plans</h2>
                    <p className="section-subtitle">Medicare Advantage Plans may include additional plan benefits.</p>
                </div>
            </div>
        </section>

        <div id="subscribe" className="section">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-6 col-md-12 col-xs-12">
                        <div className="sub-item-box">
                            <div className="icon-box"><i className="lni-bullhorn"></i></div>
                            <div className="text-box">
                                <h4>Compare Medicare Advantage Plans from multiple carriers.</h4>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="sub-item-box">
                            <div className="icon-box"><i className="lni-book"></i></div>
                            <div className="text-box">
                                <h4>Medicare Advantage plans may include additional benefits.</h4>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="sub-item-box">
                            <div className="icon-box"><i className="lni-timer"></i></div>
                            <div className="text-box">
                                <h4>Medicare Advantage Plans may be able to help limit out-of-pocket costs.</h4>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-xs-12">
                        <div className="sub-item-box">
                            <div className="icon-box"><i className="lni lni-layers"></i></div>
                            <div className="text-box">
                                <h4>Simple, No-Obligation Quotes. </h4>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="sub-item-box">
                            <div className="icon-box"><i className="lni lni-briefcase"></i></div>
                            <div className="text-box">
                                <h4>Some plans help beneficiaries to manage certain conditions.</h4>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="sub-item-box">
                            <div className="icon-box"><i className="lni lni-stats-up"></i></div>
                            <div className="text-box">
                                <h4>Some plans are designed for beneficiaries in institutional care.</h4>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="call-action section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10">
                        <h3>Medicare Advantage</h3>
                        <p>With a Medicare Advantage Plan, you may have coverage for things Original Medicare
                            doesn't cover, like [fitness programs], routine [vision], [hearing], or [dental] services.
                            Plans can also
                            choose to cover additional benefits. </p>
                    </div>
                    <div className="col-10">
                        <h3>Medicare Supplement</h3>
                        <p>Medigap plans cover all or some of the following costs, with a few exceptions:<br/>
                            Medicare Part A coinsurance and hospital costs up to an additional 365 days after
                            Medicare benefits are exhausted<br/>
                            Medicare Part B coinsurance or copayment<br/>
                            Blood (first three pints)<br/>
                            Medicare Part A hospice care coinsurance or copayment<br/>
                            Skilled nursing facility care coinsurance (Plans A and B do not cover)<br/>
                            Medicare Part A deductible (Plan A does not cover)<br/>
                            Medicare Part B deductible<br/>
                            Medicare Part B excess charge (coverage only available in Medigap Plans F and G)<br/>
                            Foreign travel exchange (coverage not available in Medigap Plans A, B, K and L)<br/>
                            Medigap Plans K and L pay 100% of covered services for the rest of the calendar year
                            after meeting the out-of-pocket limit and paying your Medicare Part B deductible.</p>
                    </div>
                </div>
                <div className="clearfix" style={{marginTop: "100px"}}><br/>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10">
                        <div className="cta-trial text-left">
                            <h3>What is Medicare Advantage Plans?</h3>
                            <p>Medicare Advantage plans often include prescription drug coverage and other benefits
                                in addition to what's covered under Original Medicare.</p>

                            <h3>Who is eligible for Medicare Advantage Plans?</h3>
                            <p>People with Medicare Parts A and B qualify for Medicare Advantage plans, although they
                                must meet enrollment period requirements [If you are new to Medicare, aging in, or
                                recently moved, you may qualify for a Medicare Advantage plan now]. Medicare Advantage
                                Plans are sold by private insurance companies and replace Original Medicare (Parts A and
                                B). They must provide the same minimum coverage as Original Medicare and may also
                                include additional benefits. Each Medicare Advantage plan has a limit on annual
                                out-of-pocket costs, which may help some beneficiaries save on healthcare expenses.</p>

                            <h3>What are Medicare Advantage Plans?</h3>
                            <p>Medicare Advantage Plans are offered by Medicare-approved private companies that must
                                follow rules set by Medicare. These plans set a limit on what you’ll have to pay
                                out-of-pocket each year for covered services. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="call-action section" style={{padding: "40px 0"}}>
            <div className="container">
                <div className="row justify-content">
                    <div className="col-12">
                        <div className="cta-trial text-left">
                            <p style={{marginBottom: "0px"}}>
                                Participating sales agencies represent Medicare Advantage [HMO, PPO and PFFS]
                                organizations and stand-alone PDP Prescription Drug Plans that are contracted with
                                Medicare. Enrollment depends on the plan’s contract renewal.

                                We do not offer every plan available in your area. Currently we represent [insert number
                                of organizations] organizations which offer [insert number of plans] products in your
                                area. Please contact Medicare.gov, 1–800–MEDICARE, or your local State Health Insurance
                                Program (SHIP) to get information on all of your options.

                                Enrollment in the described plan type may be limited to certain times of the year unless
                                you qualify for a Special Enrollment Period.

                                Not all plans offer all of these benefits. Benefits may vary by carrier and location.
                                Limitations and exclusions may apply.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <a href="#" className="back-to-top"> <i className="lni-arrow-up"></i> </a>
    </>)
}
